rect {
  fill: white;
  fill-opacity: 0;
  stroke: blue;
  stroke-width: 3;
  stroke-dasharray: 10 20;
}

rect.bounds {
  /*fill: none;*/
}

line {
  stroke: red;
  stroke-width: 2;
  stroke-dasharray: 10;
}

